:root {
  --font-family-primary: 'Plus Jakarta Sans', sans-serif;
  --font-family-secondary: 'Plus Jakarta Sans', sans-serif;
  --primary-color: #486fce;
  --primary-light-color: #dbeafd;
  --subtitle-color: rgba(0, 0, 0, 0.6);
  --title-color: #323339;
  --btn-gradient-bg-1: #23d993;
  --btn-bg: #486fce;
  --btn-gradient-bg-2: #486fce;
  --bg-color: #ffffff;
  // --card-gradient-1: #fff7e8;
  --card-gradient-2: #fff7e8;
  --link-color: #053c34;
  --link-font-weight: 400;
  --link-decoration: none;
  --icon-color: #111;
  --input-bg-color: #f3f8fe;
  --error-color: #ff7c7c;
  --offer-color: #f3f8fe;
  --red: #fd4458;
  --red-color: #ff3939;
  --green-color: #00d95f;
  --text-light-color: #414a4c;
  --text-color: #000;
  --subtle-bg-color: #e6ecfa;
  --app-bg: #f3f8fe;
}

* html {
  font-family: var(--font-family-primary);
}
html {
  scroll-behavior: smooth;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #ebdfcda2;
  }
}
